import React from 'react';
import { RouteProps } from 'react-router';

import { useUser } from "./context";
import { Route, Redirect, useLocation } from "./Routers";
import { Landing, Login, Signup, List, Verification, Invitation, Onboarding, SearchByImage } from './screens';


export type RouteType = RouteProps & {
  loadableComp: string,
  key: string,
  isProtected?: Boolean,
  routes?: RouteType[],
  options?: RouteProps,
}

export type HelperType = {
  Comp?: React.ComponentType,
  loadableComp?: string
}

export const routes = [
  {
    key: 'login',
    path: '/login',
    component: Login,
    loadableComp: '../screens/user',
  },
  {
    key: 'signup',
    path: '/signup',
    component: Signup,
    loadableComp: '../screens/user',
  },
  {
    key: 'verification',
    path: '/verification',
    component: Verification,
    loadableComp: '../screens/user',
  },
  {
    key: 'landing',
    path: '/',
    component: Landing,
    loadableComp: '../screens/home',
    options: { exact: true }
  },
  {
    key: 'invitation',
    path: '/invite',
    component: Invitation,
    loadableComp: '../screens/user',
    isProtected: true,
  },
  {
    key: 'home',
    path: '/home',
    component: List,
    loadableComp: '../screens/home',
    isProtected: true,
  },
  {
    key: 'preferences',
    path: '/preferences',
    component: Onboarding,
    isProtected: true,
    loadableComp: '../screens/user',
  },
  {
    key: 'search-image',
    path: '/search-image',
    component: SearchByImage,
    isProtected: true,
    loadableComp: '../screens',
  }
];


function createRoute(route: RouteType) {
  const { path, key, component, options } = route;
  // const comp = resolveComponent({ loadableComp, Comp })

  return <Route key={key} path={path} component={component} {...options} />;
}

function AuhtenticatedRoute(route: RouteType) {
  const { user } = useUser();
  const location = useLocation();

  if (user) return createRoute(route);
  return <Redirect key={route.key} to={{ pathname: '/login', state: { referral: location }}} />;
}

export const getRoutes = (routesData: RouteType[]): React.ReactNode => routesData.map(route => {
  const { routes, isProtected, ...rest } = route;
  if (routes) return getRoutes(routes);

  if (isProtected) return <AuhtenticatedRoute {...rest} />

  return createRoute(rest);
})
