import { useHistory, useLocation } from "../../Routers";

export default function useReferral() {
  const history = useHistory();
  const location = useLocation();
  // @ts-ignore
  let { referral } = location.state || { referral: { pathname: "/" }};

  const onComplete = (path?: string) => {
    history.replace(path || referral);
  }

  return [referral, onComplete]
};
