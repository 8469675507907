import { ElementType } from 'react';
import TextInput from './TextInput';
import CheckboxInput from './CheckboxInput';
import SelectInput from './SelectInput';


const inputTypes: { [arg0: string]: ElementType } = {
  'textinput': TextInput,
  'checkboxinput': CheckboxInput,
  'selectinput': SelectInput,
};

export default inputTypes;
