import { AppRegistry } from 'react-native';
import App from '@wineapp/shared/src/App';

import './index.css';


AppRegistry.registerComponent('wineapp', () => App)
AppRegistry.runApplication('wineapp', {
  rootTag: document.getElementById('root'),
});
