import React from 'react';
import { Image as RNImage, ImageProps } from 'react-native';

const Image = React.forwardRef(
	// @ts-ignore
	({ source, style, ...rest }: ImageProps, ref?: React.RefObject<RNImage>) => {
    return (
      <RNImage
        source={source}
        ref={ref}
        style={[
          {
            width: RNImage.resolveAssetSource(source).width,
            height: RNImage.resolveAssetSource(source).height,
          },
          style,
        ]}
        {...rest}
      />
    )
  }
);

export default Image;
