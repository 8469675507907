import React, { useState } from 'react';
import { Text, View, StyleSheet, Button } from 'react-native';
import { useForm } from 'react-hook-form';

import { useApi } from '../utils';
import { FormType } from '../forms';
import LineInput from './LineInput';


type Props<T> = {
  onComplete: (arg0: any) => void,
  endpoint: string,
  formData: FormType[],
  buttonText?: string,
  formatData?: (d: T) => void,
} 

export default function AsyncForm<T>({
  onComplete,
  endpoint,
  formData,
  buttonText,
  formatData,
}: Props<T>) {
  const { handleSubmit, errors, control, reset } = useForm();
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);
  const { submitEntity } = useApi();

  const onSubmit = async (data: any) => {
    try {
      setApiError('');
      setLoading(true);
      const formattedData = formatData ? formatData(data) : data;
      const result = await submitEntity(endpoint, formattedData);
      if (result.data.error) {
        setApiError(result.data.message);
      } else if (result.data.success) {
        onComplete(result.data.data);
        reset();
      }
    } catch(error) {
      setApiError('Api Error');
    }
    setLoading(false);
  }

  return (
    <View style={styles.container}>
      {formData.map((f: FormType) => (
        <LineInput key={f.name} error={errors[f.name]} control={control} {...f} />
      ))}
      <Text>{apiError}</Text>
      <Button
        title={buttonText || 'Submit'}
        onPress={handleSubmit(onSubmit)}
        disabled={loading}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
  }
})
