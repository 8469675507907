import React, { useState } from 'react';
import { View, StyleSheet, Text, Button } from 'react-native';

import UploadImage from './UploadImage';
import CameraWeb from './CameraWeb';

export default function Camera({ onSelect }: { onSelect: (v: any) => void }) {
  const [takePhoto, setTakePhoto] = useState<boolean>();

  if (takePhoto) return (
    <CameraWeb hide={() => setTakePhoto(false)} onSelect={onSelect} />
  )
  
  return (
    <View style={styles.container}>
      <Button title="Take Picture" onPress={() => setTakePhoto(true)} />
      <Text style={styles.spacing}>or</Text>
      <UploadImage onSelect={onSelect} />
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  spacing: {
    paddingVertical: 8,
  },
})
