import Axios, { AxiosRequestConfig } from 'axios';
// import Config from "react-native-config";

import { useUser } from '../context';


// const BASE_URL = Config.WINE_APP_BASE_URL || '/dev/';
export const BASE_URL = 'https://wdl9igkr97.execute-api.us-east-1.amazonaws.com/dev/';

const getHeaders = (token?: string | null) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (token)  return { ...headers, 'Authorization': `Bearer ${token}` };
  return headers;
};

export default function useApi() {
  const { user } = useUser();
  const token = user && user.session && user.session.id_token;

  const headers = getHeaders(token);
  return {
    searchEntities(endpoint: string, ...config: AxiosRequestConfig[]) {
      return Axios.request({
        method: 'GET',
        url: BASE_URL + endpoint,
        headers,
        ...config
      });
    },
    fetchEntities(endpoint: string, ...config: AxiosRequestConfig[]) {
      return this.searchEntities(endpoint, ...config);
    },
    submitEntity(endpoint: string, entity: {} = {}, method: 'POST' | 'PUT' | 'PATCH' = 'POST', ...config: AxiosRequestConfig[]) {
      return Axios.request({
        method,
        url: BASE_URL + endpoint,
        headers,
        data: entity,
        ...config,
      });
    },
    patchEntity(endpoint: string, entity: {}, ...config: AxiosRequestConfig[]) {
      return this.submitEntity(endpoint, entity, 'PATCH', ...config);
    },
    deleteEntity(endpoint: string, ...config: AxiosRequestConfig[]) {
      return Axios.request({
        method: 'DELETE',
        url: BASE_URL + endpoint,
        headers,
        ...config,
      });
    },
  };
}
