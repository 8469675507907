import React from 'react';
import { Text, StyleSheet } from 'react-native';
// @ts-ignore
import HTMLCamera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';


export default function CameraWeb({ onSelect, hide }: {
  onSelect: (v: any) => void,
  hide: () => void,
}) {
  return (
    <>
      <Text
        onPress={hide}
        style={styles.close}
      >
        X
      </Text>
      <HTMLCamera
        onTakePhoto = {onSelect}
        onTakePhotoAnimationDone={hide}
        idealFacingMode = {FACING_MODES.ENVIRONMENT}
        imageType = {IMAGE_TYPES.JPG}
        isFullscreen = {true}
        sizeFactor = {1}
        isMaxResolution = {true}
        idealResolution = {{width: 1024, height: 1080}}
      />
    </>
  )
}

const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    color: 'white',
    fontWeight: '900',
    fontSize: 32,
    zIndex: 1,
    right: 32,
    top: 24,
  },
})
