import React from 'react';
import { Text, View, StyleSheet, ViewStyle } from 'react-native';
import { Controller, Control, FieldValues } from 'react-hook-form';

import { FormType } from '../forms';
import inputs from './inputs';

type LineInputProps = FormType & {
  error?: { type?: string, message?: string },
  defaultValue?: string,
  control: Control<FieldValues>,
  row?: boolean,
  containerStyle?: ViewStyle,
  ExtraComponent?: React.ReactType,
}

function LineInput({
  label,
  name,
  error,
  validation,
  defaultValue = "",
  control,
  config,
  input,
  row = true,
  containerStyle,
  ExtraComponent,
}: LineInputProps) {
  const InputComponent = inputs[input];

  return (
    <View key={name} style={[styles.container, row && styles.row, containerStyle]}>
      <Text style={[row && styles.label, styles.commomLabel]}>{label}</Text>
      <View style={styles.innerContainer}>
        <Controller
          control={control}
          name={name}
          render={({ onChange, value }) => (
            <>
              <InputComponent
                onChange={onChange}
                value={value}
                {...config}
              />
              {ExtraComponent ? <ExtraComponent value={value} onChange={onChange} /> : null}
            </>
          )}
          defaultValue={defaultValue}
          rules={validation}
        />
        {error ? <Text style={styles.error}>{error.message || 'Invalid value'}</Text>: null}
      </View>
    </View>
  )
}

export default LineInput;

const spacing = { paddingVertical: 8 };

const styles = StyleSheet.create({
  container: {
    ...spacing,
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    width: 100,
  },
  innerContainer: { 
  },
  commomLabel: {
    paddingVertical: 8,
  },
  error: {
    color: 'red',
  }
})
