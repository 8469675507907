import React, { useMemo } from 'react';
import { StyleSheet, Text, View, Button } from 'react-native';
import { useForm } from 'react-hook-form';

import { LineInput } from '../../components';
import { useApi } from '../../utils';
import Wizard, { useWizard } from './Wizard';
import { useQuestions, Questions } from '../../selectors';
import { useHistory } from '../../Routers';

export default function Onboarding() {
  const questions = useQuestions();
  const { allIds, byId } = questions;
  const defaultValues = useMemo(() => {
    return Object.keys(byId).reduce((acc: { [arg0: string]: string }, curr) => {
      const { defaultValue } = byId[curr];
      if (defaultValue) acc[curr] = defaultValue;
      return acc;
    }, {});
  }, [byId]);
  

  if (!allIds.length) return <Text>Loading...</Text>;
  return <Body {...questions} defaultValues={defaultValues} />
}

function Body({ defaultValues, allIds, byId }: Questions & {
  defaultValues: { [arg0: string]: string },
}) {
  const { handleSubmit, errors, control } = useForm({
    defaultValues
  });
  const { submitEntity } = useApi();
  const history = useHistory();
  
  const onSubmit = async (d: { [arg0: string]: string }) => {
    const formatted = Object.keys(d).map((e) => ({
      question_id: e,
      answer_id: d[e]
    }));
    await submitEntity('add-flavor-profile', {
      answers: formatted,
    });
  }
  return (
    <>
      <Wizard>
        {allIds.map((k) => {
          const v = byId[k];
          return (
            <View style={styles.row} key={k}>
              <Prev />
              <LineInput
                row={false}
                control={control}
                name={k}
                label={v.question}
                input="selectinput"
                error={errors[k]}
                validation={{ required: 'Required field.' }}
                containerStyle={styles.input}
                config={{ options: v.answers.map(l => ({
                  label: l.answer, value: l.answer_id, selected: l.user_selection, }))
                }}
                ExtraComponent={({ value, onChange }) => (
                  <Revert
                    hasChanged={value !== v.defaultValue}
                    revert={() => onChange(v.defaultValue)}
                  />
                )}
              />
              <Next total={allIds.length} onSubmit={handleSubmit(onSubmit)} />
            </View>
          )
        })}
      </Wizard>
      <Text onPress={() => history.push('home')} style={styles.close}>X</Text>
    </>
  )
}


function Prev() {
  const { page, prev } = useWizard();
  if (page === 0) return null;
  return (
    <Text onPress={prev} style={[styles.prev, styles.indicator]}>{'<'}</Text>
  )
}


function Next({ total, onSubmit }: { total: number, onSubmit: () => void }) {
  const { page, next } = useWizard();

  if (page === total - 1) return (
    <View style={styles.submit}>
      <Button title="Submit" onPress={onSubmit} />
    </View>
  );
  return (
    <Text onPress={next} style={[styles.next, styles.indicator]}>{'>'}</Text>
  )
}

function Revert({ hasChanged, revert }: {
  hasChanged: boolean, revert: () => void
}) {
  if (!hasChanged) return null;
  return <Text onPress={revert}>Revert to Original</Text>
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  close: {
    position: 'absolute',
    fontSize: 32,
    top: 64,
    left: 32,
  },
  indicator: {
    fontSize: 32,
  },
  next: {
    marginRight: 16,
  },
  prev: {
    marginLeft: 16,
  },
  submit: {
    position: 'absolute',
    bottom: 32,
    width: '100%',
  },
});


