import React, { useEffect } from 'react';
import { useHistory } from '../../Routers';
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  TouchableOpacity,
  Image,
  Animated,
  ScrollView,
} from 'react-native';

import { useAnimation, jump } from '@lib/anim';

const image = {
  background: require('../../assets/background.jpg'),
  down: require('../../assets/down.png'),
  logo: require('../../assets/logo.png'),
}

const styler = (driver: Animated.Value) => ({
  transform: [
    {
      translateY: driver.interpolate({
        inputRange: [0, 0.5, 1],
        outputRange: [0, 16, 0]
      }),
    },
  ],
})
const BODY = [
  {
    title: 'Learning Platform',
    body: [
      'Help users identify wine that fits their preferences',
      'Personalized recommendations based on user\'s feedback',
      'Suggest wine and food pairing',
    ],
    background: '#E9ECEF'
  },
  {
    title: 'Concierge Service',
    body: ['Provides concierge / curation advice to assist users in acquiring wines they desire'],
    background: '#DEE2E6'
  },
  {
    title: 'Cellar management',
    body: [
      'Keep track of your inventory',
      'Find the best drinking window for your wine',
    ],
    background: '#CED4DA'
  }
]


export default function Landing() {
  // @ts-ignore
  const [animStyles, driver] = useAnimation(0, styler);
  const history = useHistory();

  useEffect(() => {
    jump(driver).start();
  }, [driver]);

  const handlePress = () => {
    history.push('/home');
  }

  return (
    <ScrollView contentContainerStyle={{ flex: 1 }}>
      <ImageBackground
        style={styles.image}
        source={image.background}
      >
        <View style={styles.container}>
          <Image source={image.logo} style={styles.logo} />
          <Text style={[styles.tagline, styles.text]}>Wine Curated Not Bought!!!</Text>
          <TouchableOpacity style={styles.button} onPress={handlePress}>
            <Text style={styles.text}>Get Started</Text>
          </TouchableOpacity>
        </View>
        <Animated.Image source={image.down} style={[styles.indicator, animStyles]} />
      </ImageBackground>
      {BODY.map(b => <Info key={b.title} {...b} />)}
    </ScrollView>
  )
}


function Info({ title, body, background }: {
  title: string, body: string[], background: string
}) {
  return (
    <View style={[styles.innerContainer, { backgroundColor: background }]}>
      <Text style={[styles.text, styles.header]} accessibilityRole="header">{title}</Text>
      {body.map((b, i) => <Text style={[styles.text, styles.body]} key={i + 1}>{b}</Text>)}
    </View>
  )
}

const styles = StyleSheet.create({
  image: {
    height: '100%',
    resizeMode: 'cover',
    alignItems: 'center',
   },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: "center",
  },
  innerContainer: {
    color: 'black',
    justifyContent: 'center', alignItems: 'center', height: '100%',
  },
  tagline: {
    paddingVertical: 24,
    fontSize: 36,
  },
  logo: {
    width: 200,
    height: 200,
  },
  button: {
    borderWidth: 1,
    paddingVertical: 8,
    borderRadius: 8,
    borderColor: 'white',
    paddingHorizontal: 16,
  },
  indicator: {
    width: 16,
    height: 16,
    margin: 36,
    tintColor: 'white'
  },
  text: { fontFamily: 'Roboto', color: 'white', },
  header: { fontSize: 64, color: 'black', marginVertical: 32 },
  body: { fontSize: 24, color: 'black', },
});
