import { useEffect } from "react";

import { useApi } from "./utils";
import { useStore } from "./Store";


type Answer = {
  answer_id: string,
  answer: string,
  user_selection: boolean,
}

export type Questions = {
  allIds: string[],
  byId: { [arg0: string]: { question: string, answers: Answer[], defaultValue?: string }},
  profile_complete: boolean,
}

export function useQuestions(): Questions {
  const { submitEntity } = useApi();
	const [state, dispatch] = useStore();

  useEffect(() => {
		async function getQuestions() {
			const { data } = await submitEntity('get-questions');

			if (data.success) {
				dispatch({ type: 'POPULATE_QUESTIONS', payload: data.data });
			}
		}
		if (!state.questions.allIds.length) {
			getQuestions();
		}
  }, []);
  
  return state.questions;
}
