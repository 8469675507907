import React, { useEffect } from 'react';
import { Text, CheckBox as RNCheckbox, CheckBoxProps, View, StyleSheet, TouchableOpacity } from 'react-native';

type Props = CheckBoxProps & {
  options: { label: string, value: boolean, selected?: boolean }[],
  onChange: (v: boolean) => void,
}

export default function CheckboxInput({ onChange, options, value, ...props }: Props) {
  return (
    <View style={[styles.container, styles.row]}>
      {options.map(o => (
        <TouchableOpacity
          // @ts-ignore
          key={o.value}
          onPress={() => onChange(o.value)}
          style={[styles.row,
          styles.innerContainer]}
        >
          <CheckBox
            onChange={() => onChange(o.value)}
            value={value === o.value}
            defaultValue={o.selected}
            {...props}
          />
          <Text style={styles.label}>{o.label}</Text>
        </TouchableOpacity>
      ))}
    </View>
  )
}

function CheckBox({ onChange, defaultValue, ...props }: {
  onChange: () => void,
  defaultValue?: boolean,
} & CheckBoxProps) {
  useEffect(() => {
    const timeout = setTimeout(() => defaultValue && onChange());
    () => clearTimeout(timeout);
  }, []);

  return (
    <RNCheckbox
      onValueChange={onChange}
      {...props}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    flexWrap: 'wrap',
  },
  innerContainer: {
    width: 200,
  },
  row: {
    flexDirection: 'row',
  },
  label: {
    paddingLeft: 8,
  }
});
