import React, { useState, useContext, useRef, useCallback } from 'react';
import { StyleSheet, View, ViewStyle, } from 'react-native';

import { TransitionView, useTransitionState } from '@lib/anim';

interface WizardCtx {
  page: number,
  next: () => void,
  prev: () => void,
}

const WizardContext = React.createContext<WizardCtx>({
  page: 0,
  prev: () => {},
  next: () => {},
});

export function useWizard() {
  const ctx = useContext(WizardContext);
  return ctx;
}

export default function Wizard({ children, start, onComplete, containerStyle }: {
  children: React.ReactNode,
  start?: number,
  onComplete?: () => void,
  containerStyle?: ViewStyle
}) {
  const width = useRef(0);
  const [page, setPage] = useState<number>(start || 0);
  const calcWidth = (e:
    { nativeEvent: { layout: { width: number }}}
  ) => { width.current = e.nativeEvent.layout.width; }
  const controller = useTransitionState(page);

  const wizAnimStyle = useCallback((driver, state, otherState) => ({
    ...StyleSheet.absoluteFillObject,
    transform: [
      {
        translateX: driver.interpolate({
          inputRange: [0, 1],
          outputRange: [Math.sign(state - otherState) * width.current, 0],
        }),
      },
    ],
  }), []);

  const pages = React.Children.toArray(children);
  const next = useCallback(() => {
    if (page === pages.length - 1) {
      onComplete && onComplete();
    } else {
      setPage(p => p + 1);
    }
  }, [page]);

  const prev = useCallback(() => {
    if (page !== 0) {
      setPage(p => p - 1);
    } 
  }, [page]);
 
  const context = { page, next, prev };

  return (
    <View style={[styles.container, containerStyle]} onLayout={calcWidth}>
      <WizardContext.Provider value={context}>
        {pages.map((p, idx) => (
          <TransitionView
            key={idx + 1}
            style={wizAnimStyle}
            state={idx}
            controller={controller}
          >
            {p}
          </TransitionView>
        ))}
      </WizardContext.Provider>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  }
});
