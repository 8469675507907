import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';

type Props = {
  value: boolean,
  options: { label: string, value: boolean, selected?: boolean }[],
  onChange: (v: boolean) => void,
}

export default function SelectInput({ onChange, options, value }: Props) {
  return (
    <View style={[styles.container, styles.row]}>
      {options.map(o => {
        const selected = value ? value === o.value : o.selected;
        return (
          <TouchableOpacity
            // @ts-ignore
            key={o.value}
            onPress={() => onChange(o.value)}
            style={[styles.innerContainer, selected && styles.selected]}
          >
            <Text style={styles.label}>{o.label}</Text>
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexWrap: 'wrap',
  },
  selected: {
    backgroundColor: '#0005'
  },
  innerContainer: {
    width: 100,
    height: 100,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: 'gray',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    marginRight: 16,
    marginTop: 16,
    backgroundColor: '#0001'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  label: {
  }
});
