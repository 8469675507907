import React, { useState, useEffect } from 'react';
import { Text } from 'react-native';

import { Router, Route, Switch } from './Routers';
import { routes, getRoutes } from './routes';
import User from './User';
import { Session } from './session';
import { AppProvider } from './context';
import Store from './Store';


function App() {
  const [user, setUser] = useState<User | null>();

  useEffect(() => {
    User.load().then(setUser);
  }, []);

  const onLogin = async (session: Session) => {
    session.timestamp = new Date().getTime();
    await User.create(session).then(setUser);
  }

  if (user === undefined) return <Text>Loading</Text>;
  if (user) user.onLogout = () => setUser(null);

  return (
    <Store>
      <AppProvider value={{ user, onLogin }}>
        <Router /* history={history} */>
          <Switch>
            { getRoutes(routes) }
            <Route path="*">
              <Text>404. Route not found</Text>
            </Route>
          </Switch>
        </Router>
      </AppProvider>
    </Store>
  )

}

export default App;
