import { useState, useCallback } from 'react';

export default function useToggle(initial = false): [
  string | boolean,
  () => void,
  () => void,
  () => void
]{
  const [visible, setVisible] = useState<boolean | string>(initial);
  
  const toggle = useCallback(() => setVisible(v => !v), []);
  const open = useCallback(() => setVisible(true), []);
  const close = useCallback(() => setVisible(false), []);

  return [
    visible, 
    toggle,
    open,
    close,
  ]
}
