import React, { useState } from 'react';
import { View, StyleSheet, Text, Image, Button } from 'react-native';

import { Camera } from '../components';
import { useApi, useToggle } from '../utils';

export default function SearchByImage() {
	const [error, setError] = useToggle();
	const [photo, setPhoto] = useState<string>("");
	const [results, setResults] = useState<null | {
		wines: {score: number, wine: string}[],
		words: string[]
	}>();
	const { submitEntity } = useApi();

	const searchByImage = async (image: string) => {
		const { data } = await submitEntity('search-wine-by-image', { image });
		if (data.error) setError();
		if (data.success) {
			setResults(data.data)
		}
	}
	const imgStyles = results ? { height: 160, width: 240 } : { height: 320, width: 480 };


	if (photo) return (
		<View style={styles.container}>
			<View style={results ? styles.searchBar : styles.container}>
				<Image style={imgStyles} source={{ uri: photo }} />
				<View style={[results ? styles.searchBar : styles.horizontalSpacing]}>
					{results ? null : (
						<>
							<Button title="Search wine" onPress={() => searchByImage(photo)} />
							<Text style={styles.spacing}>or</Text>
						</>
					)}
					<Button title="Clear Selected" onPress={() => { setPhoto(''); setResults(null); }} />
				</View>
			</View>
			{results ? (
				<View style={styles.container}>
					{results.wines.map(wine => (
						<View>
							<Text>Score: {wine.score}</Text>
							<Text>Name: {wine.wine}</Text>
						</View>
					))}
					<Text>Words: {results.words.join(',')}</Text>
				</View>
			) : null}
		</View>
  )

	return (
		<View style={styles.container}>
			<Camera onSelect={setPhoto} />
			<Text>{error && 'Error while searching'}</Text>
		</View>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
	},
	searchBar: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	spacing: {
    padding: 8,
	},
	horizontalSpacing: {
		paddingVertical: 8,
		alignItems: 'center',
  },
})
