import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { read, write } from '@lib/storage';

import { AsyncForm } from '../../components';
import { invitationsForm } from '../../forms';
import { pluralize } from '../../utils';
import { useHistory } from '../../Routers';

const INVITAION_KEY = 'wine_app_inviation';

export default function Invitation() {
  const history = useHistory();
  const [invitation, setInvitation] = useState(0);
  const handleComplete = async (data: { invitations_remaining: number }) => {
    await write(INVITAION_KEY, data.invitations_remaining);
    setInvitation(data.invitations_remaining);
  }

  useEffect(() => {
    async function fetchData() {
      const data = await read(INVITAION_KEY);
      setInvitation(data);
    }
    fetchData();
  }, [])

  return (
    <View style={styles.container}>
      <AsyncForm
        endpoint="invite-user"
        onComplete={handleComplete}
        formData={invitationsForm}
      />
      {invitation ? (
        <Text>{`You have ${pluralize(invitation, 'invitation')} remaining`}</Text>
      ): null}
      <Text style={styles.text} onPress={() => history.push('/home')}>Go to home</Text>
    </View>
  )
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
  }
});
