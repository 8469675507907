import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';

import { useUser } from '../../context';
import useReferral from './useReferral';
import { AsyncForm } from '../../components';
import { verificationForm, resendVerificationForm } from '../../forms';
import { Redirect } from '../../Routers';

export default function Verification() {
  const [referral, onComplete] = useReferral();
  const { user } = useUser();
  const [toggle, setToggle] = useState<boolean>(false);

  if (user) return <Redirect to={referral} />


  const body = toggle ? (
    <AsyncForm
      formData={resendVerificationForm}
      endpoint="resend-confirmation"
      onComplete={() => { setToggle(false); }}
    />
  ): (
  <>
    <AsyncForm
      formData={verificationForm}
      endpoint="confirm-signup"
      onComplete={() => onComplete('/login')}
    />
    <Text>Did not recieve code?</Text>
    <Text onPress={() => setToggle(true)}>Send verification code again.</Text>
  </>
  )
  return (
    <View style={styles.container}>{body}</View>
  )
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }
});
