import { read, write } from '@lib/storage';

export const SESSION_KEY = 'wine_app_session';

export interface Session {
	id_token: string,
	refresh_token: string,
	token_type: 'Bearer',
	expires_in: number,
	timestamp: number,
}

const hasExpired = (time: number, interval: number): boolean => {
	const now = new Date().getTime();

	return (now - time) / 1000 > interval;
}

export const loadSession = async () => {
	const data: Session = await read(SESSION_KEY);

	if (!data) return null;
	if (hasExpired(data.timestamp, data.expires_in)) {
		await clearSession();
		return null;
	}
	return data;
}

export const startSession = async (session: Session) => {
	await write(SESSION_KEY, session);
}

export const clearSession = async () => {
	await write(SESSION_KEY, '');
}
