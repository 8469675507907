import React from 'react';
import { Text, SafeAreaView } from 'react-native';

import { useUser } from '../../context';
import { useHistory, Redirect } from '../../Routers';
import { useQuestions } from '../../selectors';

export default function List() {
	const { user } = useUser();
	const history = useHistory();
	const { allIds, profile_complete } = useQuestions();

	if (allIds.length && !profile_complete) {
		return <Redirect to="/preferences" />;
	}
	return (
		<SafeAreaView>
			<Text>Welcome. Here are the lists.</Text>
			<Text onPress={() => history.push('/invite')}>Invite friends</Text>
			<Text onPress={() => history.push('/preferences')}>Edit preferences</Text>
			<Text onPress={() => history.push('/')}>Go to Landing page</Text>
			<Text onPress={() => history.push('/search-image')}>Search Wine</Text>
			<Text onPress={() => user && user.logout()}>Logout</Text>
		</SafeAreaView>
	)
}
