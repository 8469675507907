import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';

import { useUser } from '../../context';
import { AsyncForm } from '../../components';
import { loginForm, FormType, signUpForm, resendVerificationForm } from '../../forms';
import useReferral from './useReferral';
import { Redirect } from '../../Routers';
import { Session } from '../../session';
import { lowerTrim } from '../../utils';

type Props<T> = {
  title: string,
  formData: FormType[],
  onComplete: (data: Session) => Promise<void> | void,
  cta: string,
  subtitle: string,
  referral: { pathname: string },
  onRoute: (path?: string) => void,
  formatData?: (d: T) => void,
  children?: React.ReactNode,
}


function LoginOrSignUp<T>({
  title,
  cta,
  subtitle,
  referral,
  onRoute,
  children,
  ...rest
}: Props<T>) {
  const { user } = useUser();
  if (user) return <Redirect to={referral} />

  const handlePress = () => {
    onRoute(lowerTrim(cta));
  }

  return (
    <View style={styles.container}>
      <Text>{title}</Text>
      <AsyncForm
        endpoint={lowerTrim(title)}
        {...rest}
      />
      {children}
      <Text>{subtitle}</Text>
      <TouchableOpacity onPress={handlePress}>
        <Text>{cta}</Text>
      </TouchableOpacity>
    </View>
  )
};

const Login = () => {
  const { onLogin } = useUser();
  const [referral, onRoute] = useReferral();
  const [toggle, setToggle] = useState<boolean>(false);

  const handleLogin = async (data: Session) => {
    await onLogin(data);
    onRoute();
  }

  if (toggle) return (
    <View style={styles.container}>
      <AsyncForm
        endpoint="forgot-password"
        formData={resendVerificationForm}
        onComplete={() => setToggle(false)}
      />
      <Text onPress={() => setToggle(false)}>Back to Login</Text>
    </View>
  )
  return (
    <LoginOrSignUp
      onComplete={handleLogin}
      formData={loginForm}
      subtitle="Don't have an account?"
      title="Login"
      cta="Sign up"
      referral={referral}
      onRoute={onRoute}
    >
      <Text onPress={() => setToggle(true)}>Forgot password?</Text>
    </LoginOrSignUp>
  )
}

const Signup = () => {
  const [referral, onRoute] = useReferral();
  const handleSignup = () => {
    onRoute('/verification')
  }
  const handleData = (d: { email: string }) => {
    return { ...d, username: d.email };
  }
  return (
    <LoginOrSignUp
      onComplete={handleSignup}
      formData={signUpForm}
      subtitle="Already have an account?"
      title="Sign up"
      cta='Login'
      referral={referral}
      onRoute={onRoute}
      formatData={handleData}
    />
  )
}

export { Login, Signup };

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }
})