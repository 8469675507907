import { TextInputProps } from "react-native";
import { ValidationRules } from "react-hook-form";

import { lowerTrim, EMAIL_REGEX } from "./utils";

type InputType = 'textinput' | 'checkboxinput' | 'selectinput';
export interface FormType {
  name: string,
  label: string,
  validation?: ValidationRules,
  config?: TextInputProps & {
    options?: { label: string, value: string, selected?: boolean}[]
  },
  input: InputType,
  options?: {}[],
}

const createField = ({ name, label, validation, config = {}, input }: {
  label: string,
  name?: string,
  validation? : {},
  config? : {},
  input?: InputType
}): FormType => {
  const trimmed = lowerTrim(label);
  // @ts-ignore
  if (!input && !config.placeholder) config.placeholder = `Enter ${trimmed}`;
  return {
    name: name || trimmed,
    label,
    validation: { required: 'Required field.', ...validation },
    config,
    input: input || 'textinput'
  }
}


const name = createField({ label: 'Name' });
const userName = createField({ label: 'Username', validation: { minLength: 3 } });
const email = createField({ label: 'Email', validation: { pattern: EMAIL_REGEX, } })
const password = createField({
  label: 'Password',
  validation: { minLength: 8 },
  config: { secureTextEntry: true }
});

export const loginForm = [ userName, password, ];
export const signUpForm = [
  name,
  email,
  createField({
    label: 'Invitation code',
    name: 'invitation_code',
    validation: { minLength: 6, maxLength: 6, },
    config: { placeholder: 'Enter Access Code', },
  }),
  password,
];

export const verificationForm = [
  userName,
  createField({ label: 'Verification code', name: 'code' }),
]

export const resendVerificationForm = [ userName ];
export const invitationsForm = [ email ];
