import React from 'react';
import { TextInput as RNTextInput, TextInputProps } from 'react-native';

type Props = TextInputProps & {
  onChange: (v: string|number) => void
}

function TextInput({ onChange, ...props}: Props) {
  return (
    <RNTextInput onChangeText={value => onChange(value)} {...props} />
  )
}

export default TextInput;
