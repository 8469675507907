import React, { useState, ChangeEvent } from 'react';
import { Text, } from 'react-native';

const valid_types = ['image/png', 'image/jpeg'];
const max_size = 2000000;
const validateMimeType = (file: File) => {
  return valid_types.some(type => file.type === type);
};
const validateSize = (file: File) => {
  return file.size < max_size;
};
const convertToBase64 = async (file: File) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject();
  })
}

export default function UploadImage({ onSelect }: { onSelect: (v: string) => void }) {
  const [error, setError] = useState('');

  
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    // @ts-ignore
    const file = e.target.files[0];
    if (!file) setError('');
    if (!validateMimeType(file)) return setError('Invalid image type');
    if (!validateSize(file)) return setError('Image too large');
    const f = await convertToBase64(file);
    // @ts-ignore
    onSelect(f);
  }

  return (
    <>
      <label className="camera_label_wine" htmlFor="file">Choose File</label>
      <input
        className="camera_input_wine"
        type="file"
        name="file"
        id="file"
        onChange={handleChange}
      />
      {error ? <Text>{error}</Text> : null}
    </>
  )
}
