import React, { useReducer, Dispatch, useContext } from 'react';

interface NormalizedState {
  allIds: string[],
  byId: { [arg0: string]: {} }
}

interface Action {
  type: string,
  payload: any,
}

const INITIAL_STATE = { allIds: [], byId: {} };

const questionsReducer = (state: NormalizedState, action: Action) => {
  switch(action.type) {
    case 'POPULATE_QUESTIONS': {
      const { profile_complete, ...questions } = action.payload;
      return {
        profile_complete,
        allIds: Object.keys(questions),
        byId: Object.keys(questions).reduce((acc, curr) => {
          const val = questions[curr];
          // @ts-ignore
          acc[curr] = val;
          // @ts-ignore
          const defaultVal = val.answers.find(a => a.user_selection);
          // @ts-ignore
          if (defaultVal) acc[curr].defaultValue = defaultVal.answer_id;
          return acc;
        }, {}),
      }
    }
    default: 
      return state;
  }
}

function combineReducers(reducers: { [arg0: string]: [{}, Dispatch<Action>] }) {
  const state = Object.keys(reducers).reduce(
    (acc, key) => ({ ...acc, [key]: reducers[key][0] }),
    {}
  );
 
  const dispatch = (action: Action) =>
    Object.keys(reducers)
      .map(key => reducers[key][1])
      .forEach(fn => fn(action));
 
  return [state, dispatch];
}

const StateContext = React.createContext<{} | null>(null);
export const useStore = () => {
  //@ts-ignore
  const { state, dispatch } = useContext(StateContext);
  return [state, dispatch];
}


export default function Store({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = combineReducers({
    questions: useReducer(questionsReducer, { ...INITIAL_STATE, profile_complete: false, }),
  });

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  )
}
