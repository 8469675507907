import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';

type Props = {
  value: string,
  onChange: (arg0: string) => void,
  options: string[],
}

export default function TabInput({ value, onChange, options }: Props) {
  return (
    <View style={styles.container}>
      {options.map((option) => {
        const selected = option === value;
        const selectedStyle = selected ? { backgroundColor: 'gray' }: {};

        const handleChange = () => onChange(option);

        return (
          <TouchableOpacity key={option} onPress={handleChange} style={[styles.tab, selectedStyle]}>
            <Text style={styles.text}>{option}</Text>
          </TouchableOpacity>
        )
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  tab: { flex: 1, alignItems: 'center' },
  text: { padding: 8, textTransform: 'capitalize' },
})
